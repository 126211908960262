<template>
    <div class="home">
		<div class="oneBox" v-intersect="onIntersect">
			<v-fab-transition>
				<div class="oneContent" transition="slide-y-reverse-transition" v-show="isIntersecting">
					<div class="boxTitle">企业级视频直播营销平台</div>
					<div class="one_listBox ">
						<div class="listItem">
							<img class="listIcon" src="../assets/images/timerse/icon-point.png" alt="">
							<p class="itemName">拉流直播</p>
						</div>
						<div class="listItem">
							<img class="listIcon" src="../assets/images/timerse/icon-point.png" alt="">
							<p class="itemName">拉流直播</p>
						</div>
						<div class="listItem">
							<img class="listIcon" src="../assets/images/timerse/icon-point.png" alt="">
							<p class="itemName">拉流直播</p>
						</div>
						<div class="listItem">
							<img class="listIcon" src="../assets/images/timerse/icon-point.png" alt="">
							<p class="itemName">拉流直播</p>
						</div>
					</div>
					<div class="scriptBox">一分钟搭建专属企业直播间，高效传播获客，助力业务增长</div>
				</div>
			</v-fab-transition>
		</div>
		<div class="twoBox block-scene">
			<div class="twoContent">
				<div class="boxTitle">多种视频直播场景应用</div>
				<div class="listBox">
					<v-hover  v-slot="{hover}">
						<div class="listItem oneImg" :class="hover ? 'bigSize':''">企业内训</div>
					</v-hover>
					<v-hover  v-slot="{hover}">
						<div class="listItem threeImg" :class="hover ? 'bigSize':''">会议直播</div>
					</v-hover>
					<v-hover  v-slot="{hover}">
						<div class="listItem oneImg" :class="hover ? 'bigSize':''">企业内训</div>
					</v-hover>
					<v-hover  v-slot="{hover}">
						<div class="listItem eightImg" :class="hover ? 'bigSize':''">招聘直播</div>
					</v-hover>
					<v-hover  v-slot="{hover}">
						<div class="listItem sixImg" :class="hover ? 'bigSize':''">购物直播</div>
					</v-hover>
					<v-hover  v-slot="{hover}">
						<div class="listItem eightImg" :class="hover ? 'bigSize':''">招聘直播</div>
					</v-hover>
					<v-hover  v-slot="{hover}">
						<div class="listItem threeImg" :class="hover ? 'bigSize':''">会议直播</div>
					</v-hover>
					<v-hover  v-slot="{hover}">
						<div class="listItem sixImg" :class="hover ? 'bigSize':''">购物直播</div>
					</v-hover>
				</div>
			</div>
		</div>
		<div class="threeBox block-scene">
			<div class="content">
				<div class="boxTitle">企业视频直播一站式服务</div>
				<div class="item">
					<div class="left">
						<div class="itemTitle">画质高清，稳定流畅</div>
						<div class="spanList">
							<div class="spanItem">
								<p class="threeScript">1080P高清画质，支持百万用户同时观看，春晚、世界杯同级别稳定性</p>
							</div>
							<div class="spanItem">
								<p class="threeScript">2800+网络节点智能调度，保证弱网环境直播流畅播放</p>
							</div>
							<div class="spanItem">
								<p class="threeScript">多套CDN加速网络备份，智能网络测试，平台服务稳定</p>
							</div>
						</div>
						<div class="threeBig">
							<div class="bigLeft">
								<div class="p1">1080<span>p</span></div>
								<div class="p2">高清画质</div>
							</div>
							<div class="line"></div>
							<div class="bigRight">
								<div class="p1">2800<span>+</span></div>
								<div class="p2">网络节点</div>
							</div>
						</div>
					</div>
					<img class="right" src="../assets/images/timerse/video_1.png" alt="">
				</div>
				<div class="paginationBox">
					<div class="left">
						<span>01</span>/05
					</div>
					<div class="right">
						<v-progress-linear :value="20"></v-progress-linear>
					</div>
				</div>
				<div class="arrowBox">
					<img class='leftIcon' src="../assets/images/timerse/left.png" alt="">
					<img class='rightIcon' src="../assets/images/timerse/right.png" alt="">
				</div>
			</div>
		</div>
		<div class="fourBox block-scene">
			<div class="content">
				<div class="boxTitle">丰富功能，贴合更多视频直播应用场景</div>
				<div class="fourList">
					<v-hover v-for="(item,index) in 8" :key="index" v-slot="{hover}">
						<div class="fourItem" :class="`elevation-${hover ? 6 : 0}`">
							<img class="leftImg" src="../assets/images/timerse/icon-1@2x.png" alt="">
							<div class="rightBox">
								<p class="p1">直播推流 SDK</p>
								<p class="p2">提供音视频采集、处理、编码及推流能力，支持RTMP、SRT、QUIC 等推流协议，支持多种终端和各类客户场景。</p>
							</div>
						</div>
					</v-hover>
				</div>
			</div>
		</div>
		<div class="fiveBox block-scene">
			<div class="content">
				<div class="boxTitle">产品架构</div>
				<img class='fiveImg' src="../assets/images/timerse/image@2x.png" alt="">
			</div>
		</div>
		<div class="sixBox block-scene">
			<div class="content">
				<div class="boxTitle">我们服务的客户</div>
				<v-tabs class='sixTabs' centered>
					<v-tab>腾讯</v-tab>
					<v-tab>抖音</v-tab>
					<v-tab>斗鱼</v-tab>
					<v-tab-item v-for="n in 3" :key="n" style="margin-top: 20px;">
						<v-container fluid>
							<v-row>
								<v-col v-for="i in 6" :key="i" cols="12" md="4" >
									<v-hover v-slot="{hover}">
										<v-img :class="hover ? 'elevation-12 bigImg':'elevation-0'" :src="`https://picsum.photos/500/300?image=${i * n * 5 + 10}`" :lazy-src="`https://picsum.photos/10/6?image=${i * n * 5 + 10}`" aspect-ratio="1" ></v-img>
									</v-hover>
								</v-col>
							</v-row>
						</v-container>
					</v-tab-item>
				</v-tabs>
			</div>
		</div>
		<div class="sevenBox">
			<div class="content">
				<div class="text flexCenter flexColumn">
					<div class="p1">鹭播科技，致力成为被用户信赖的直播服务商</div>
					<div class="phone"><span>服务热线：</span>18960381890</div>
					<div class="p2">资深视频直播专家为您服务</div>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
export default {
	data(){
		return {
			hover: true,
			isIntersecting: false,
		}
	},
	methods: {
		onIntersect (entries, observer) {
			console.log(entries,observer)
			
			setTimeout(() => {
				this.isIntersecting = entries[0].isIntersecting
			}, 500);
		},
    },
}
</script>
<style lang='scss'>

</style>

<style lang="scss" scoped>
	.home {
		.block-scene {
			position: relative;
			padding-top: 80px;
			padding-bottom: 100px;
			box-sizing: border-box;
		}
		.oneBox {
			display: block;
			background: url('../assets/images/timerse/professional_pc_banner.png') center center no-repeat;
			background-size: cover;
			height: calc(100vh - 60px);
			width: 100%;
			overflow: hidden;
			.oneContent {
				position: relative;
				z-index: 9;
				margin: 20vh 0 0 18.75%;

				.boxTitle {
					font-size: 64px;
					font-family: CKTAlibabaPuHuiTi;
					font-weight: bold;
					color: #ffffff;
					letter-spacing: 6px;
				}
				.one_listBox {
					display: flex;
					align-items: center;
					justify-content: space-between;
					flex-wrap: wrap;
					margin-top: 40px;
					width: 390px;
					.listItem {
						display: flex;
						align-items: center;
						margin-bottom: 37px;
						.listIcon {
							width: 26px;
							height: 26px;
							margin-right: 14px;
						}
						.itemName {
							font-size: 30px;
							font-weight: 300;
							color: #ffffff;
							margin-bottom: 0px;;
						}

					}
				}
				.scriptBox {
					font-size: 21px;
					font-weight: 300;
					color: #ffffff;
					margin-top: 44px;
				}
			}
		}
		.twoBox {
			.twoContent {
				position: relative;
				width: 1200px;
				margin: 0 auto;
				.boxTitle {
					font-size: 46px;
					font-weight: 400;
					color: #232323;
					margin-bottom: 46px;
					text-align: center;
				}
				.listBox {
					display: flex;
					flex-wrap: wrap;
					.listItem {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 280px;
						height: 200px;
						font-size: 21px;
						font-weight: 500;
						color: #ffffff;
						flex-shrink: 0;
						margin-right: 25px;
						margin-top: 25px;
						border-radius: 5px;
						transition: background-size .5s;
					}
					.listItem:nth-child(4n) {
						margin-right: 0;
					}
					.listItem:nth-child(8n) {
						margin-right: 0;
					}
					.oneImg {
						background: url('../assets/images/timerse/one.png');
						background-size: 100% 100%;
						background-position: center center;
					}
					.threeImg {
						background: url('../assets/images/timerse/three.png');
						background-size: 100% 100%;
						background-position: center center;
					}
					.sixImg {
						background: url('../assets/images/timerse/six.png');
						background-size: 100% 100%;
						background-position: center center;
					}
					.eightImg {
						background: url('../assets/images/timerse/eight.png');
						background-size: 100% 100%;
						background-position: center center;
					}
					.bigSize {
						background-size: 120% 120%;
					}
				}
			}
		}
		.threeBox {
			background: #f7f8fa;
			.content {
				position: relative;
				width: 1200px;
				margin: 0 auto;
				.boxTitle {
					font-size: 46px;
					font-weight: 400;
					color: #232323;
					text-align: center;
				}
				.item {
					position: relative;
					display: flex;
					align-items: center;
					.left {
						width: 700px;
						height: 754px;
						padding-left: 43px;
						padding-top: 220px;
						box-sizing: border-box;
						.itemTitle {
							font-size: 35px;
							font-weight: 500;
							color: #232323;
							margin-bottom: 36px;
							white-space: nowrap;
						}
						.spanList {
							.spanItem {
								.threeScript {
									display: flex;
									align-items: center;
									font-size: 16px;
									font-weight: 400;
									color: #464646;
									margin-top: 15px;
								}
								.threeScript::before {
									content: '';
									display: inline-block;
									width: 5px;
									height: 5px;
									background: #0475ff;
									border-radius: 50%;
									margin-right: 6px;
								}
							}
						}
						.threeBig {
							display: flex;
							align-items: center;
							margin-top: 48px;
							.bigLeft , .bigRight {
								font-size: 40px;
								font-family: CKTAlibabaPuHuiTi;
								font-weight: 800;
								color: #0475ff;
								.p1 {
									margin-bottom: 16px;
									span {
										font-size: 24px;
									}
								}
								.p2 {
									font-size: 16px;
									font-weight: 300;
									color: #969696;
								}
							}
							.line {
								width: 1px;
								height: 50px;
								background: #dcdcdc;
								margin: 0 30px 0 25px;
							}
						}
					}
					.right {
						position: absolute;
						right: -33px;
						width: 700px;
						height: 754px;
						flex-shrink: 0;
					}
				}
				.paginationBox {
					position: absolute;
					bottom: 162px;
					left: 43px;
					display: flex;
					align-items: flex-end;
					.left {
						margin-right: 27px;
						font-size: 16px;
						font-family: CKTAlibabaPuHuiTi;
						font-weight: bold;
						color: #848484;
						span {
							font-size: 21px;
							font-family: CKTAlibabaPuHuiTi;
							font-weight: bold;
							color: #1f1f1f;
						}
					}
					.right {
						width: 250px;
					}
				}
				.arrowBox {
					position: absolute;
					z-index: 99999;
					top: 50%;
					width: 100%;
					.leftIcon {
						position: absolute;
						left: -52px;
						top: 0;
						width: 41px;
						height: 41px;
						cursor: pointer;
					}
					.rightIcon {
						position: absolute;
						right: -63px;
						top: 0;
						width: 41px;
						height: 41px;
						cursor: pointer;
					}
				}
			}
		}
		.fourBox {
			.content {
				position: relative;
				width: 1200px;
				margin: 0 auto;
				.boxTitle {
					font-size: 46px;
					font-weight: 400;
					color: #232323;
					text-align: center;
					margin-bottom: 46px;
				}
				.fourList {
					display: flex;
					flex-wrap: wrap;
					.fourItem {
						display: flex;
						width: 580px;
						height: 130px;
						background: #ffffff;
						border-radius: 5px;
						box-sizing: border-box;
						padding: 25px 20px 18px 29px;
						margin-top: 10px;
						margin-right: 10px;
						.leftImg {
							width: 51px;
							height: 51px;
							margin-right: 29px;
						}
						.rightBox {
							.p1 {
								font-size: 21px;
								font-weight: 500;
								color: #232323;
								margin-bottom: 13px;
							}
							.p2 {
								font-size: 16px;
								font-weight: 300;
								color: #646464;
								line-height: 28px;
							}
						}
					}
				}
			}
		}
		.fiveBox {
			background: #f7f8fa;
			.content {
				position: relative;
				width: 1200px;
				margin: 0 auto;
				.boxTitle {
					font-size: 46px;
					font-weight: 400;
					color: #232323;
					text-align: center;
					margin-bottom: 46px;
				}
				.fiveImg {
					width: 100%;
				}
			}
		}
		.sixBox {
			.content {
				position: relative;
				width: 1200px;
				margin: 0 auto;
				.boxTitle {
					font-size: 46px;
					font-weight: 400;
					color: #232323;
					text-align: center;
					margin-bottom: 46px;
				}
				.sixTabs {
					.v-tab {
						font-size: 24px;
					}
					
				}
			}
		}
		.sevenBox {
			background: url('../assets/images/timerse/zx-banner.png') center center no-repeat;
			background-size: cover;
			position: relative;
			height: 710px;
			.content {
				position: relative;
				width: 1200px;
				margin: 0 auto;
				height: 100%;
				.text {
					height: 100%;
					text-align: center;
					.p1 {
						font-size: 40px;
						font-weight: bold;
						color: #ffffff;
					}
					.phone {
						font-size: 80px;
						font-weight: bold;
						color: #ffffff;
						margin: 100px 0;
						span {
							font-size: 40px;
						}
					}
					.p2 {
						font-size: 24px;
						color: #ffffff;
					}
				}
			}
		}
	}
</style>
