<template>
	<div class="home">
		<div class="threeBox">
			<div class="scriptTitle">FEELWORLD Light</div>
			<div class="detailScript">
				<div class="script">
					FEELWORLD Light是一款灯光控制app，通过蓝牙传输指令，调控灯光模式、亮度、灯光效果重复频率。
					集成了多款产品灯光产品控制应用，根据产品蓝牙协议不同，灯光应用场景不同，对灯光控制功能进行不同的开发。
					支持对单独灯光进行操作，也支持多灯光同时操作，对灯光模式、亮度等进行同步。
					应用内加入了场景功能，可实现一键灯光场景还原。
				</div>
			</div>
			<div class="detailImgBox">
				<v-img class="itemImg" :width="800" cover src="../assets/images/detailImg/4.png"></v-img>
				<v-img class="itemImg" :width="800"  cover src="../assets/images/detailImg/5.png"></v-img>
				<v-img class="itemImg" :width="800" cover src="../assets/images/detailImg/6.png"></v-img>
			</div>
		</div>
		<lzy-Footer></lzy-Footer>
	</div>
</template>

<script>
	import Footer from "../components/Footer2.vue";
	export default {
		components: {
			"lzy-Footer": Footer,
		},
		data() {
			return {
				hover: true,
				isIntersecting: false,
				type:'',
			}
		},
		mounted(){
		},
		methods: {
			
		},
	}
</script>
<style lang="scss" scoped>
	.home {
		.scriptTitle {
			margin-top: 20px;
			font-size: 26px;
			text-align: center;
		}
		.detailScript {
			display: flex;
			align-items: center;
			justify-content: center;
			.script {
				width: 800px;
				font-size: 18px;
				margin-top: 20px;
			}
		}
		.block-scene {
			position: relative;
			padding-top: 80px;
			padding-bottom: 100px;
			box-sizing: border-box;
		}
		.detailImgBox {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction:column;
			.itemImg {
				margin: 20px 0;
			}
		}
	}
</style>