<template>
	<div class="home">
		<div class="threeBox" v-if="type == 'PLUS'">
			<div class="scriptTitle">FEELWORLD LIVE PLUS</div>
			<div class="detailScript">
				<div class="script">
					FEELWORLD LIVE PLUS是一款视频混合切换台控制app，通过Wi-Fi传输指令，辅助L4设备进行控制。
					L4包含5个输入画面，1个处理画面和1个输出画面，设备显示空间有限，对于操作来说，比较繁琐。
					通过APP尽可能的显示主要的功能控制界面，在同一个画面可以做更多操作，使操作更加方便、快捷、简单。
				</div>
			</div>
			<div class="detailImgBox">
				<v-img class="itemImg" :width="800" cover src="../assets/images/detailImg/1.png"></v-img>
				<v-img class="itemImg" :width="800"  cover src="../assets/images/detailImg/2.png"></v-img>
				<v-img class="itemImg" :width="800" cover src="../assets/images/detailImg/3.png"></v-img>
			</div>
		</div>
		<div class="threeBox" v-else-if="type == 'Light'">
			<div class="scriptTitle">FEELWORLD Light</div>
			<div class="detailScript">
				<div class="script">
					FEELWORLD Light是一款灯光控制app，通过蓝牙传输指令，调控灯光模式、亮度、灯光效果重复频率。
					集成了多款产品灯光产品控制应用，根据产品蓝牙协议不同，灯光应用场景不同，对灯光控制功能进行不同的开发。
					支持对单独灯光进行操作，也支持多灯光同时操作，对灯光模式、亮度等进行同步。
					应用内加入了场景功能，可实现一键灯光场景还原。
				</div>
			</div>
			<div class="detailImgBox">
				<v-img class="itemImg" :width="800" cover src="../assets/images/detailImg/4.png"></v-img>
				<v-img class="itemImg" :width="800"  cover src="../assets/images/detailImg/5.png"></v-img>
				<v-img class="itemImg" :width="800" cover src="../assets/images/detailImg/6.png"></v-img>
			</div>
		</div>
		<div class="threeBox" v-else-if="type == 'FeelworldLive'">
			<div class="scriptTitle">FeelworldLive</div>
			<div class="detailScript">
				<div class="script">
					FeelworldLive是一款视频混合切换台控制app，通过Wi-Fi传输指令，辅助L2设备进行控制。L4包含4个输入画面，1个处理画面和1个输出画面，设备画面尺寸较小，没有安排直接操作的界面，都是需要通过按钮弹出操作页面，对于操作来说，过程比较繁琐。
					通过APP尽可能的显示主要的功能控制界面，在同一个画面可以做更多操作，使操作更加方便、快捷、简单。
				</div>
			</div>
			<div class="detailImgBox">
				<v-img class="itemImg" :width="800" cover src="../assets/images/detailImg/7.png"></v-img>
				<v-img class="itemImg" :width="800"  cover src="../assets/images/detailImg/8.png"></v-img>
				<v-img class="itemImg" :width="800" cover src="../assets/images/detailImg/9.png"></v-img>
			</div>
		</div>
		<div class="threeBox" v-else-if="type == 'FEELWORLD'">
			<div class="scriptTitle">FEELWORLD</div>
			<div class="detailScript">
				<div class="script">
					FEELWORLD是一款提词器app，编辑制作台词，将台词添加到一个滚动的画面中，调整滚动速度，字体大小，文本和背景颜色调整，调整到一个个人舒服的台词呈现样式，优化实际应用场景中的台词提示。
				</div>
			</div>
			<div class="detailImgBox">
				<v-img class="itemImg" :width="800" cover src="../assets/images/detailImg/10.png"></v-img>
				<v-img class="itemImg" :width="800"  cover src="../assets/images/detailImg/11.png"></v-img>
				<v-img class="itemImg" :width="800" cover src="../assets/images/detailImg/12.png"></v-img>
			</div>
		</div>
		<div class="threeBox" v-else-if="type == 'WIRLESS'">
			<div class="scriptTitle">Feelworld Wireless</div>
			<div class="detailScript">
				<div class="script">
					Feelworld Wireless是为Feelworld无线视频发射器设计的应用程序。
					Feelworld Wireless允许你从iOS设备上的HDMI接口摄像头观看实时视频，你可以使用Feelworld Wireless记录和捕捉实时视频的感觉世界。
					Feelworld Wireless支持对接收到的实时视频进行参数设置，包括分辨率和比特率。
				</div>
			</div>
			<div class="detailImgBox">
				<v-img class="itemImg" :width="800" cover src="../assets/images/detailImg/13.png"></v-img>
				<v-img class="itemImg" :width="800"  cover src="../assets/images/detailImg/14.png"></v-img>
				<v-img class="itemImg" :width="800" cover src="../assets/images/detailImg/15.png"></v-img>
			</div>
		</div>
		<lzy-Footer></lzy-Footer>
	</div>
</template>

<script>
	import Footer from "../components/Footer2.vue";
	export default {
		components: {
			"lzy-Footer": Footer,
		},
		data() {
			return {
				hover: true,
				isIntersecting: false,
				type:'',
			}
		},
		mounted(){
			this.type = this.$route.params.type
		},
		methods: {
			
		},
	}
</script>
<style lang="scss" scoped>
	.home {
		.scriptTitle {
			margin-top: 20px;
			font-size: 26px;
			text-align: center;
		}
		.detailScript {
			display: flex;
			align-items: center;
			justify-content: center;
			.script {
				width: 800px;
				font-size: 18px;
				margin-top: 20px;
			}
		}
		.block-scene {
			position: relative;
			padding-top: 80px;
			padding-bottom: 100px;
			box-sizing: border-box;
		}
		.detailImgBox {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction:column;
			.itemImg {
				margin: 20px 0;
			}
		}
	}
</style>