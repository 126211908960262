<template>
	<div class="home">
		<div class="oneBox" v-intersect="onIntersect">
			<v-fab-transition>
				<div class="oneContent" transition="slide-y-reverse-transition" v-show="isIntersecting">
					<div class="boxTitle">领先的IT公司</div>
				</div>
			</v-fab-transition>
		</div>
		<div class="threeBox block-scene">
			<div class="threeContent">
				厦门鹭播科技有限公司，坐落于美丽的海滨城市厦门，是一家在科技推广和应用服务领域具有深厚实力和广泛影响力的企业。
				<p></p> 
				厦门鹭播科技有限公司成立于2021年，是视瑞特的全资子公司，专注于科技推广与应用服务的创新型企业。 
				<p></p> 
				公司秉承科技驱动发展的理念，致力于为客户提供全方位的电子产品销售、广播影视设备销售以及广播电视传输设备销售等服务。
				<p></p> 
				同时，鹭播科技还涉足集成电路芯片及产品销售、电子元器件批发与零售等领域，以满足不同行业对高科技产品的需求。
			</div>
		</div>
		<lzy-Footer></lzy-Footer>
	</div>
</template>

<script>
	import Footer from "../components/Footer2.vue";
	export default {
		components: {
			"lzy-Footer": Footer,
		},
		data() {
			return {
				hover: true,
				isIntersecting: false,
			}
		},
		methods: {
			onIntersect(entries, observer) {
				console.log(entries, observer)

				setTimeout(() => {
					this.isIntersecting = entries[0].isIntersecting
				}, 500);
			},
		},
	}
</script>
<style lang="scss" scoped>
	.home {
		.block-scene {
			position: relative;
			padding-top: 80px;
			padding-bottom: 100px;
			box-sizing: border-box;
		}

		.oneBox {
			display: block;
			background: url('../assets/images/timerse/6.webp') center center no-repeat;
			background-size: cover;
			height: calc(50vh - 60px);
			width: 100%;
			overflow: hidden;

			.oneContent {
				position: relative;
				z-index: 9;
				margin: 20vh 0 0 0;
				text-align: center;

				.oneBtnBox {
					margin-top: 20px;

					.oneBtn {
						color: #3F51B5;
						width: 150px;
						height: 50px;
						font-size: 16px;
						margin-right: 20px;
					}
				}

				.boxTitle {
					font-size: 64px;
					font-family: CKTAlibabaPuHuiTi;
					font-weight: bold;
					color: #2196F3;
					letter-spacing: 6px;
				}

				.one_listBox {
					display: flex;
					align-items: center;
					justify-content: space-between;
					flex-wrap: wrap;
					margin-top: 40px;
					width: 390px;

					.listItem {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						margin-bottom: 37px;

						.listIcon {
							width: 26px;
							height: 26px;
							margin-right: 14px;
						}

						.itemName {
							font-size: 30px;
							font-weight: 300;
							color: #ffffff;
							margin-bottom: 0px;
							;
						}

					}
				}

				.scriptBox {
					font-size: 21px;
					font-weight: 300;
					color: #ffffff;
					margin-top: 44px;
				}
			}
		}

		.twoBox {
			.twoContent {
				position: relative;
				width: 1200px;
				margin: 0 auto;

				.boxTitle {
					font-size: 46px;
					font-weight: 400;
					color: #232323;
					margin-bottom: 46px;
					text-align: center;
				}

				.listBox {
					display: flex;
					flex-wrap: wrap;

					.listItem {

						// display: flex;
						// justify-content: center;
						// flex-direction: column;
						// align-items: center;
						.imgBox {
							width: 280px;
							height: 200px;
							flex-shrink: 0;
							margin-right: 20px;
							margin-top: 25px;
							border-radius: 5px;
							transition: background-size .5s;
							padding: 0 20px;
							cursor: pointer;
						}

						.imgScript {
							width: 280px;
							font-size: 22px;
							font-weight: 500;
							margin-top: 20px;
							color: #3F51B5;
						}
					}

					.listItem:nth-child(4n) {
						margin-right: 0;
					}

					.listItem:nth-child(8n) {
						margin-right: 0;
					}

					.oneImg {
						background-image: url('../assets/images/timerse/3.webp');
						background-size: 100% 100%;
						background-position: center center;
					}

					.threeImg {
						background-image: url('../assets/images/timerse/4.webp');
						background-size: 100% 100%;
						background-position: center center;
					}

					.sixImg {
						background-image: url('../assets/images/timerse/5.webp');
						background-size: 100% 100%;
						background-position: center center;
					}

					.eightImg {
						background-image: url('../assets/images/timerse/2.webp');
						background-size: 100% 100%;
						background-position: center center;
					}

					.bigSize {
						background-size: 120% 120%;
					}
				}
			}
		}

		.threeBox {
			background: #f7f8fa;

			.threeContent {
				font-size: 18px;
				color: #2196F3;
				padding: 30px 100px;
			}
		}

		.fourBox {
			.content {
				position: relative;
				width: 1200px;
				margin: 0 auto;

				.boxTitle {
					font-size: 46px;
					font-weight: 400;
					color: #232323;
					text-align: center;
					margin-bottom: 46px;
				}

				.fourList {
					display: flex;
					flex-wrap: wrap;

					.fourItem {
						display: flex;
						width: 580px;
						height: 130px;
						background: #ffffff;
						border-radius: 5px;
						box-sizing: border-box;
						padding: 25px 20px 18px 29px;
						margin-top: 10px;
						margin-right: 10px;

						.leftImg {
							width: 51px;
							height: 51px;
							margin-right: 29px;
						}

						.rightBox {
							.p1 {
								font-size: 21px;
								font-weight: 500;
								color: #232323;
								margin-bottom: 13px;
							}

							.p2 {
								font-size: 16px;
								font-weight: 300;
								color: #646464;
								line-height: 28px;
							}
						}
					}
				}
			}
		}

		.fiveBox {
			background: #f7f8fa;

			.content {
				position: relative;
				width: 1200px;
				margin: 0 auto;

				.boxTitle {
					font-size: 46px;
					font-weight: 400;
					color: #232323;
					text-align: center;
					margin-bottom: 46px;
				}

				.fiveImg {
					width: 100%;
				}
			}
		}

		.sixBox {
			.content {
				position: relative;
				width: 1200px;
				margin: 0 auto;

				.boxTitle {
					font-size: 46px;
					font-weight: 400;
					color: #232323;
					text-align: center;
					margin-bottom: 46px;
				}

				.sixTabs {
					.v-tab {
						font-size: 24px;
					}

				}
			}
		}

		.sevenBox {
			background: url('../assets/images/timerse/zx-banner.png') center center no-repeat;
			background-size: cover;
			position: relative;
			height: 710px;

			.content {
				position: relative;
				width: 1200px;
				margin: 0 auto;
				height: 100%;

				.text {
					height: 100%;
					text-align: center;

					.p1 {
						font-size: 40px;
						font-weight: bold;
						color: #ffffff;
					}

					.phone {
						font-size: 80px;
						font-weight: bold;
						color: #ffffff;
						margin: 100px 0;

						span {
							font-size: 40px;
						}
					}

					.p2 {
						font-size: 24px;
						color: #ffffff;
					}
				}
			}
		}
	}
</style>