<template>
	<div class="home">
		<div class="threeBox">
			<div class="scriptTitle">Feelworld Wireless</div>
			<div class="detailScript">
				<div class="script">
					Feelworld Wireless是为Feelworld无线画面发射器设计的应用程序。
					Feelworld Wireless允许你从iOS设备上的HDMI接口摄像头观看实时画面，你可以使用Feelworld Wireless记录和捕捉实时画面的感觉世界。
					Feelworld Wireless支持对接收到的实时画面进行参数设置，包括分辨率和比特率。
				</div>
			</div>
			<div class="detailImgBox">
				<v-img class="itemImg" :width="800" cover src="../assets/images/detailImg/13.png"></v-img>
				<v-img class="itemImg" :width="800"  cover src="../assets/images/detailImg/14.png"></v-img>
				<v-img class="itemImg" :width="800" cover src="../assets/images/detailImg/15.png"></v-img>
			</div>
		</div>
		<lzy-Footer></lzy-Footer>
	</div>
</template>

<script>
	import Footer from "../components/Footer2.vue";
	export default {
		components: {
			"lzy-Footer": Footer,
		},
		data() {
			return {
				hover: true,
				isIntersecting: false,
				type:'',
			}
		},
		mounted(){
		},
		methods: {
			
		},
	}
</script>
<style lang="scss" scoped>
	.home {
		.scriptTitle {
			margin-top: 20px;
			font-size: 26px;
			text-align: center;
		}
		.detailScript {
			display: flex;
			align-items: center;
			justify-content: center;
			.script {
				width: 800px;
				font-size: 18px;
				margin-top: 20px;
			}
		}
		.block-scene {
			position: relative;
			padding-top: 80px;
			padding-bottom: 100px;
			box-sizing: border-box;
		}
		.detailImgBox {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction:column;
			.itemImg {
				margin: 20px 0;
			}
		}
	}
</style>