<template>
	<div class="home">
		<div class="threeBox">
			<div class="scriptTitle">FEELWORLD</div>
			<div class="detailScript">
				<div class="script">
					FEELWORLD是一款提词器app，编辑制作台词，将台词添加到一个滚动的画面中，调整滚动速度，字体大小，文本和背景颜色调整，调整到一个个人舒服的台词呈现样式，协助实际应用场景中的台词提示。
				</div>
			</div>
			<div class="detailImgBox">
				<v-img class="itemImg" :width="800" cover src="../assets/images/detailImg/10.png"></v-img>
				<v-img class="itemImg" :width="800"  cover src="../assets/images/detailImg/11.png"></v-img>
				<v-img class="itemImg" :width="800" cover src="../assets/images/detailImg/12.png"></v-img>
			</div>
		</div>
		
		<lzy-Footer></lzy-Footer>
	</div>
</template>

<script>
	import Footer from "../components/Footer2.vue";
	export default {
		components: {
			"lzy-Footer": Footer,
		},
		data() {
			return {
				hover: true,
				isIntersecting: false,
				type:'',
			}
		},
		mounted(){
		},
		methods: {
			
		},
	}
</script>
<style lang="scss" scoped>
	.home {
		.scriptTitle {
			margin-top: 20px;
			font-size: 26px;
			text-align: center;
		}
		.detailScript {
			display: flex;
			align-items: center;
			justify-content: center;
			.script {
				width: 800px;
				font-size: 18px;
				margin-top: 20px;
			}
		}
		.block-scene {
			position: relative;
			padding-top: 80px;
			padding-bottom: 100px;
			box-sizing: border-box;
		}
		.detailImgBox {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction:column;
			.itemImg {
				margin: 20px 0;
			}
		}
	}
</style>