<template>
	<div class="home">
		<div class="threeBox">
			<div class="scriptTitle">FEELWORLD LIVE PLUS</div>
			<div class="detailScript">
				<div class="script">
					FEELWORLD LIVE PLUS是一款画面混合切换台控制app，通过Wi-Fi传输指令，辅助L4设备进行控制。
					L4包含5个输入画面，1个处理画面和1个输出画面，设备显示空间有限，对于操作来说，比较繁琐。
					通过APP尽可能的显示主要的功能控制界面，在同一个画面可以做更多操作，使操作更加方便、快捷、简单。
				</div>
			</div>
			<div class="detailImgBox">
				<v-img class="itemImg" :width="800" cover src="../assets/images/detailImg/1.png"></v-img>
				<v-img class="itemImg" :width="800"  cover src="../assets/images/detailImg/2.png"></v-img>
				<v-img class="itemImg" :width="800" cover src="../assets/images/detailImg/3.png"></v-img>
			</div>
		</div>

		<lzy-Footer></lzy-Footer>
	</div>
</template>

<script>
	import Footer from "../components/Footer2.vue";
	export default {
		components: {
			"lzy-Footer": Footer,
		},
		data() {
			return {
				hover: true,
				isIntersecting: false,
				type:'',
			}
		},
		mounted(){
		},
		methods: {
			
		},
	}
</script>
<style lang="scss" scoped>
	.home {
		.scriptTitle {
			margin-top: 20px;
			font-size: 26px;
			text-align: center;
		}
		.detailScript {
			display: flex;
			align-items: center;
			justify-content: center;
			.script {
				width: 800px;
				font-size: 18px;
				margin-top: 20px;
			}
		}
		.block-scene {
			position: relative;
			padding-top: 80px;
			padding-bottom: 100px;
			box-sizing: border-box;
		}
		.detailImgBox {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction:column;
			.itemImg {
				margin: 20px 0;
			}
		}
	}
</style>